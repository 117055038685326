<!--会议管理系统-->
<template>
  <div class="meeting-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="会议管理系统" @btnClick="bannerClick"
                  imgStyle="width:496px;height:496px" :imgSrc="`${require('@/assets/img/solution/会议管理系统.png')}`"
                  contentStyle="width:90%"/>
    <!--会议管理系统帮你解决难题-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">会议管理系统帮你解决难题</p>
        <div class="module-col">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="col-content-1">
                <p class="col-title">高效获客</p>
                <p class="col-content">多样裂变玩法结合全员专属二维码宣发</p>
                <p class="col-content">自建传播渠道，溯源效果明确清晰</p>
                <p class="col-content">自建传播渠道，溯源效果明确清晰</p>
                <p class="col-content" style="margin-block-end: 50px;">根据群体画像，定向邀约高匹配客群</p>
                <div class="col-img"></div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="col-content-2">
                <p class="col-title">自动化会议流程</p>
                <p class="col-content">0代码、5分钟填充式搭建线下会议、线上直播</p>
                <p class="col-content">点选式设置线索来源渠道、用户行为标签</p>
                <p class="col-content">一站式完成全流程参会设置，自动化推进</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">沉淀会议在线数据</p>
                <p class="col-content">记录客户会议行为，分析用户需求偏好</p>
                <p class="col-content">数据同步营销团队，持续进行针对性触达</p>
                <p class="col-content">客户档案支持销售掌握客户动态，及时跟进</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="col-content-2">
                <p class="col-title">加深线索认可</p>
                <p class="col-content">支持多类场景设置不同参会条件，智能签到</p>
                <p class="col-content">丰富的互动游戏组件自助使用，亮点吸睛</p>
                <p class="col-content">会议各环节支持行动号召，引导用户下载留资</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">量化活动效果</p>
                <p class="col-content">邀约、报名、参会、下载，量化每个环节</p>
                <p class="col-content">客户观看行为分析，把控客户关注点</p>
                <p class="col-content">个性化勾选表头，生成会议营销分析报表</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!--会议管理系统核心亮点-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">会议管理系统核心亮点</p>
        <div class="module-tabs">
          <template v-for="list of datalist2">
            <div :class="activeName==list.value ? 'active tabs-list' : 'tabs-list'" @click="activeName=list.value">
              {{ list.name }}
            </div>
          </template>
        </div>
        <div class="tabs-content">
          <el-row :gutter="40">
            <template v-for="data of datalist2">
              <template v-if="data.value==activeName">
                <el-col :span="24">
                  <el-row :gutter="40">
                    <el-col :span="12">
                      <template v-for="data2 of data.list">
                        <div class="tabs-col">
                          <p class="tabs-title">{{ data2.name }}</p>
                          <ul>
                            <template v-for="ul of data2.children">
                              <li class="list-style-type">{{ ul }}</li>
                            </template>
                          </ul>
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="12">
                      <div class="col-img"
                           :style="'background: url('+require('@/assets/img/solution/'+data.image)+') no-repeat'"/>
                    </el-col>
                  </el-row>
                </el-col>
              </template>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--产品优势-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title">产品优势</p>
        <div class="module-col">
          <el-row :gutter="30">
            <el-col :span="8">
              <div class="col-content">
                <p class="title">智能化</p>
                <p class="content">通过统一的数据管理、身份认证及消息通讯，建立会议管理平台，实现会议资源、议题的管理。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="col-content">
                <p class="title">电子化</p>
                <p class="content">实现会前、会中、会后的信息化管理与控制，做到会前安排电子化，会中管理智能化，会后文档流转规范化。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="col-content">
                <p class="title">平台化</p>
                <p class="content">通过会议议题审批、会议消息推送，摆脱时间与空间的限制，实现会议纪要等会议文档的流转、审批和归档。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'会议管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";
export default {
name: "meeting",
  components: {NewCloudeFooter, NewCloudeHeader,headerBanner,applyTrial},
  data(){
    return{
      bannerContent:'适用于会议管理多类应用场景，覆盖线上直播和线下活动的全流程管理，为市场人设计合理的流程和收集全面的用户信息，大幅提升活动管理效率，优化活动转化设计，不断提升活动ROI',
      activeName: '1',
      datalist2:[
        {
          name: '报名缴费',
          value: '1',
          image:'报名缴费.png',
          list: [
            {
              name: '报名管理',
              children: [
                '支持多通道报名，自定义报名表单', '支持参会者代报名', '支持报名审核'
              ]
            },
            {
              name: '缴费管理',
              children: [
                '支持设置多种门票类型', '支持报名缴费', '支持申请发票'
              ]
            },
          ]
        },
        {
          name: '签到核销',
          value: '2',
          image: '签到核销.png',
          list: [
            {
              name: '电子签到',
              children: [
                '二维码核销', '扫码签到', '闸机核销'
              ]
            },
            {
              name: '适用场景',
              children: [
                '主/分会场入场签到', '接机接站', '餐券核销','物品核销'
              ]
            },
          ]
        },
        {
          name: '主分会场管理',
          value: '3',
          image: '主分会场管理.png',
          list: [
            {
              name: '多会场管理',
              children: [
                '支持多场会场日程、嘉宾信息展示', '支持不同会场在线报名、购票缴费、签到核销', '支持现场主/分会场签到数据统计'
              ]
            }
          ]
        },
      ]
    }
  },
  methods:{
    bannerClick(){
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.meeting-container{
  background: #FFFFFF;
  .module-1 {
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .col-content-1{
          background: #F7F8FF;
          padding: 30px 20px 0px;
          height: 430px;
          .col-img{
            width: 314px;
            height: 206px;
            background: url("~@/assets/img/solution/2023728162528.png");
            background-size: 100% 100%;
            margin-left: 50px;
          }
        }
        .col-content-2{
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 20px;
          height: 160px;
        }
        .col-title{
          border-left: 4px solid #1935DE;
          padding-left: 10px;
          font-size: 18px;
          line-height: 18px;
          color: #333333;
          font-family: 'SourceHanSansCN';
          margin-block-start: 0px;
          margin-block-end: 15px;
        }
        .col-content{
          font-size: 16px;
          color: #333333;
          font-family: 'SourceHanSansCN';
          margin-block-start: 0px;
          margin-block-end: 15px;
        }
      }
    }
  }
  .module-2 {
    background: #F7F8FE;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px 40px;
      .module-tabs {
        width: 1000px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 60px;
        border: 1px solid #DDDDDD;
        background: #F7F8FE;

        .tabs-list {
          color: #333333;
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          padding-bottom: 10px;
          padding-top: 10px;
          width: 33.33333%;
          text-align: center;
          border-radius: 60px;
          cursor: pointer;
        }

        .active {
          background: #1935DE;
          color: #FFFFFF;
        }
      }
      .tabs-content{
        margin-top: 50px;
        .tabs-col {
          padding: 30px 20px 20px;
          background: #FFFFFF;
          margin-bottom: 30px;

          .tabs-title {
            font-size: 18px;
            color: #333333;
            font-family: 'SourceHanSansCN';
            margin-block-start: 0px;
            margin-block-end: 0px;
          }

          ul {
            margin-block-start: 20px;
            margin-block-end: 0px;
            margin-inline-start: 15px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }

          .list-style-type {
            list-style-type: "◆";
            padding-left: 10px;
            color: #333333;
            font-family: "SourceHanSansCN";
            padding-bottom: 10px;
          }

          ::marker {
            color: #1935DE;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: xx-small;
          }
        }
        .col-img{
          width: 100%;
          background-size: 100% 100% !important;
          height: 426px;
        }
      }
    }
  }
  .module-3 {
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px 60px;
      .col-content{
        background: #F7F8FE;
        padding: 30px 20px;
        height: 115px;
        .title{
          margin-block-end: 20px;
          margin-block-start: 0px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          font-size: 18px;
        }
        .content{
          margin-block-end: 0px;
          margin-block-start: 0px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          font-size: 16px;
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
